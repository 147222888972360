$block: '.quantity-input';

#{$block} {
    #{$block}__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        width: 90px;
        height: 28px;
        margin-left: auto;
        position: relative;
    }

    #{$block}__button {
        width: 28px;
        height: 28px;
        vertical-align: middle;

        span {
            margin-top: 3px;
        }
    }

    #{$block}__input {
        width: 30px !important;

        input {
            text-align: center;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    #{$block}__error-text-start {
        position: absolute;
        top: 100%;
        text-align: start;
        width: 100%;
    }

    #{$block}__error-text-end {
        position: absolute;
        top: 100%;
        text-align: end;
        width: 100%;
    }
}