$block: '.option';

#{$block} {
    display: flex;
    flex-direction: row;
    padding: 8px 0px;

    #{$block}__title {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    #{$block}__switch {
        margin-left: auto;
    }
}