$block: '.contact-card';

#{$block} {
    position: absolute;
    top: calc(50% + 10px);
    left: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 28px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 24px;

    #{$block}__contact-container {
        display: grid;
        grid-template-columns: (121px auto);
        column-gap: 16px;

        #{$block}__contact-info {
            display: flex;
            flex-direction: column;
            gap: 8px;

            #{$block}__contact-number {
                position: relative;
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                    transition: opacity 0.3s ease;
                }

                img:hover {
                    cursor: default;
                    opacity: 0.5;
                }
            }
        }
    }

    #{$block}__separator {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    #{$block}__emty-data{
        margin-top: 12px;
        opacity: .5;
    }
}

#{$block}__tooltip-container .g-popup__content {
    border-radius: 5px;
    padding: 0 !important;
}

#{$block}__tooltip {
    padding: 12px 16px;
}

#{$block}__copy {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 5px 12px;
    max-width: max-content;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

#{$block}__whatsapp-tooltip-container {
    .g-popup__content {
        border-radius: 5px;
        padding: 0 !important;
        background-color: rgba(76, 76, 76, 0.85);
    }
}

#{$block}__whatsapp-tooltip {
    padding: 8px 12px;
}

#{$block}__whatsapp-tooltip-content {
    color: white;
}

#{$block}__whatsapp-link {
    display: flex;
}