@use '@assets/styles/auth-variables.scss' as av;
@use '@features/search/variables.scss' as sv;

$block: '.section-header';

#{$block} {
    background-color: av.$main-background;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    #{$block}__progress {
        margin-left: 16px;
        margin-top: auto;
        margin-bottom: auto;
        flex-grow: 1;
    }

    .g-progress__item {
        animation:
            progress-animation 13s linear forwards,
            g-loading-animation 0.5s linear infinite;
    }

    @keyframes progress-animation {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0%);
        }
    }

    #{$block}__alert {
        position: fixed;
        top: 84px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1010;
        padding: 8px 20px;
        background-color: rgba(225, 239, 253, 1);

        .g-flex {
            align-items: center;
        }

        .g-color-text_color_info {
            display: flex;
        }

        .g-icon {
            vertical-align: middle;
        }
    }

    #{$block}__icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        opacity: 0.3;
    }

    #{$block}__icon_rotation {
        transform: rotate(-90deg);
    }
}
