@use '@assets/styles/variables.scss' as variables;

$block: '.contact-form';

#{$block} {
  & .g-modal__content {
    /* Form_feedback */
    /* Auto layout */
    box-sizing: border-box;
    width: 468px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 64px;
    gap: 20px;
    isolation: isolate;

    position: absolute;
    left: calc(50% - 468px/2);
    top: 5rem;

    /* Base Float/Modal

    --g-color-base-modal

    Floating components with a veil (like popups)
    */
    background: #FFFFFF;
    border-radius: 20px;

    font-family: Inter, sans-serif;

    .g-text_variant_header-2 {
      //styleName: Header/Header 2;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    #{$block}__input {
      margin-top: 15px;
      width: 100%;
      &:last-child {
        margin-top: 30px;
      }
      &:first-child {
        margin-top: 0;
      }
    }

    #{$block}__input > * {
      width: 100%;
    }
  }
}