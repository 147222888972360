$block: '.delivery-date';

#{$block} {
    padding: 11px 12px;
    text-align: right;

    &__custom-order {
        display: flex;
        flex-direction: column;
        text-align: start;
    }

    #{$block}__expected-date {
        display: flex;
        align-items: center;
        gap: 4px;
        
        #{$block}__expected-icon {
            color: rgba(0, 0, 0, 0.3)
        }
    }

}