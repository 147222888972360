@use '@assets/styles/auth-variables.scss' as av;

$block: '.auth-wrapper';

#{$block} {
    background-color: av.$main-background;
    min-height: 100%;
    min-width: 1280px;

    #{$block}__container {
        margin-left: auto;
        margin-right: auto;
        min-width: 1212px;
        max-width: 1604px;
        width: calc(100% - 68px);
    }
}
