$block: '.scroll-button';

#{$block} {
    position: fixed;
    right: 4vw;
    bottom: 38px;
    padding: 10px 0 0;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    z-index: 99;
    --g-button-border-radius: 15px;

    svg {
        width: 20px;
        height: 20px;
        color: #000;
    }

    &:hover {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

#{$block}_hidden {
    display: none;
}

#{$block}_rotate {
    transform: rotate(180deg);
}