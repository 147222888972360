@use '@assets/styles/variables.scss' as variables;
@use '@features/search/variables.scss' as sv;

$block: '.search-bar';

#{$block} {
    background-color: sv.$header-background;
    border-radius: sv.$default-border-radius;
    padding: 12px 40px 12px 40px;
    height: 44px;
    position: relative;
    z-index: 10;

    #{$block}__search-fields {
        display: flex;
        flex-direction: row;
        height: 100%;
        column-gap: 12px;

        input {
            background-color: #ffffff;
        }

        #{$block}__search-input-group {
            flex-grow: 1;
            input {
                max-width: calc(100% - 95px);
            }

            #{$block}__search-suggestions {
                position: relative;
                background-color: #ffffff;
                box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
                margin-top: 8px;
                border-radius: 6px;
                z-index: 0;
            }

            #{$block}__search-suggestions-items {
                padding: 4px 0px 4px 4px;
            }

            #{$block}__search-suggestions-item {
                padding: 8px 16px;
                border-radius: 8px;

                .g-list__item-content {
                    white-space: nowrap;
                }
            }

            #{$block}__search-suggestions-inner-item {
                width: 100%;

                #{$block}__search-suggestions-brand {
                    display: flex;
                }
            }

            #{$block}__search-suggestions-filter {
                padding: 8px 12px;
                margin-block-end: 0px;
                border-bottom: 1px solid #e5e5e5;

                .g-text-input__content {
                    border-style: none;
                }

                input {
                    pointer-events: none;
                    padding: 5px 0px;
                }
            }

            #{$block}__search-input-group-container {
                position: relative;
            }
        }
    }

    .g-text-input__content {
        background-color: #fff;
    }
}

#{$block}.l {
    padding: 40px;
}

#{$block}.s {
    padding: 0;
    background-color: #ffffff;
    width: 392px;

    @media (max-width: 1375px) {
        width: 300px;
    }

    #{$block}__search-button {
        margin-left: 10px;

        #{$block}__search-icon {
            color: rgba(0, 0, 0, 0.5);
        }
    }
}
