$block: '.store-address';

#{$block} {
    display: flex;
    max-width: 520px;
    height: max-content;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__address {
        margin-top: 20px;
        display: inline-flex;
        align-items: flex-start;
    }

    // #{$block}__address:nth-of-type(2) {
    //     margin-top: 32px;
    // }

    #{$block}__icon {
        margin-right: 8px;
        min-width: 20px;
        min-height: 20px;
    }

    #{$block}__worktime-container {
        display: flex;
        margin-top: 20px;

        #{$block}__worktime-content {
            display: flex;
            flex-direction: column;

            #{$block}__content-day {
                display: inline-block;
                min-width: 35px;
            }

            #{$block}__content:not(:first-of-type) {
                margin-top: 8px;
            }
            #{$block}__content-time {
                display: inline-block;
                min-width: 45px;
            }

        }
    }
}