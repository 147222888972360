@use '../../../assets/styles/variables.scss' as variables;

$block: '.at-button';

#{$block} {
  background-color: #E58345;
  color: white;

  &:hover {
    background-color: #E9AE56 !important;
    --_--background-color-hover: none;
    color: white;
  }
}

#{$block}.g-button_loading {
  --g-button-background-color: #FFBE5C;
}

#{$block}__gray {
  background-color: #D5D8DA;
  color: black;

  &:hover {
    background-color: #E9AE56 !important;
    --_--background-color-hover: none;
  }
}

#{$block}__white {
  background-color: white;
  color: black;

  &:hover {
    background-color: #E9AE56 !important;
    --_--background-color-hover: none;
  }
}

#{$block}__gray.g-button_loading {
  --g-button-background-color: #FFBE5C;
}