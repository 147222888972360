$block: '.tooltip-container';

#{$block} {

    display: flex;
        justify-content: space-between;
        gap: 16px;

    &__copy {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        padding: 5px 10px;
        width: max-content;
        height: 28px;
        transition: background-color 0.3s ease;
        box-sizing: border-box;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}