$block: '.manager-info';

#{$block} {
    display: flex;
    min-width: 398px;
    height: max-content;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__manager {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        width: 100%;

        #{$block}__firstname {
            margin: 12px 0;
        }
    }

    #{$block}__manager:first-of-type {
        margin-top: 32px;
    }

    #{$block}__manager:not(:last-child) {
        border-bottom: 1px solid rgba($color: #000000, $alpha: .05);
        padding-bottom: 20px;
    }

    #{$block}__content {
        margin-top: 12px;
        display: flex;

        #{$block}__link {
            margin-left: 8px;
            align-self: baseline;
        }

        #{$block}__whatsapp-tooltip-container .g-popup__content {
            border-radius: 5px;
            padding: 0 !important;
            background: rgba(76, 76, 76, 0.85);
            right: 0;
        }

        #{$block}__whatsapp-tooltip {
            font-family: Inter, sans-serif;
            padding: 8px 12px;
        }

        #{$block}__whatsapp-tooltip-content {
            color: white;
        }
    }

    #{$block}__emty-data{
        margin-top: 12px;
        opacity: .5;
    }
}