@use '@assets/styles/variables.scss' as variables;
@use '@features/search/variables' as sv;

$block: '.item';

#{$block} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-top: 1px solid #b2b2b2;

    #{$block}__item-header {
        width: sv.$search-header-width;
        position: sticky;
        top: 267px;

        @media screen and (min-width: sv.$wide-breakpoint) {
            width: sv.$search-header-width-wide;
        }
    }

    #{$block}__proposals {
        width: sv.$proposals-width;

        @media screen and (min-width: sv.$wide-breakpoint) {
            width: sv.$proposals-width-wide;
        }

        #{$block}__show-more-container {
            padding-top: 4px;
            padding-right: 8px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: 4px;
            cursor: pointer;

            #{$block}__show-more {
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }

            #{$block}__show-more-icon_rotation {
                transform: rotate(180deg);
            }
        }
    }
}

#{$block}:first-child {
    border-top: 1px solid #e5e5e5;
}

#{$block}:last-child {
    border-bottom: 1px solid #e5e5e5;
}
