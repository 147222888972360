$block: '.basket-items-out';

#{$block}__header {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 12px;
    gap: 18px;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
}
