$block: '.custom-select-orders';

#{$block} {
    width: 220px;
    max-height: max(calc(100vh - 700px), 300px);
    display: flex;
    flex-direction: column;

    .g-select-list__option {
        margin: 0 4px;
        padding: 0 12px;
        border-radius: 6px;

        .g-select-list__tick-icon {
            color: rgba(189, 142, 75, 1);
        }
    }

    #{$block}__header-search {
        padding: 8px;
        border-bottom: 1px solid var(--Line-Generic, rgba(0, 0, 0, 0.1));

        span {
            padding: 0 !important;
        }
    }

    #{$block}__header-button {
        padding: 12px 8px;
        border-bottom: 1px solid var(--Line-Generic, rgba(0, 0, 0, 0.1));

        #{$block}__button-outlined {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    #{$block}__footer-button {
        display: flex;
        gap: 8px;
        padding: 8px;
        border-top: 1px solid var(--Line-Generic, rgba(0, 0, 0, 0.1));
        width: auto;

        #{$block}__button-cancel {
            flex: 1;
        }
    }
}

#{$block}__empty-options {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    padding: 4px 12px;
}

.multiple-selected .g-select-control__button {
    width: auto;
}

.multiple-selected .g-select-control__label {
    margin-inline-end: 0;
}

.multiple-selected .g-select-control__option-text {
    display: none;
}

.multiple-selected .g-select-counter_size_m {
    background: none;
    min-width: auto;
    margin: 0;
}
