@use '@assets/styles/variables.scss' as variables;

$block: '.range-group';

#{$block} {

    #{$block}__header {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 8px 0px;

        #{$block}__title {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            flex-grow: 1;
        }

        #{$block}__delete-button {
            display: none;
            width: 20px;
            height: 20px;
            align-self: self-end;
    
            span {
                margin-top: 2px;
            }
    
            svg {
                width: 12px;
                height: 12px;
            }
        }
    
        #{$block}__delete-button:hover {
            display: flex;
        }
    }

    #{$block}__values {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }


    #{$block}__title:hover + #{$block}__delete-button {
        display: flex;
    }
}