$block: '.orders-items';

#{$block} {
    display: flex;
    flex-direction: column;
    width: 100%;

    #{$block}__row {
        display: grid;
        border-bottom: 1px solid #e5e5e5;
        align-items: start;
        justify-content: space-between;

        &:hover {
            background-color: rgba(107, 132, 153, 0.15);
        }

        #{$block}__date {
            padding: 11px 12px;
        }
    
        #{$block}__status {
            padding: 11px 12px;
        }
    
        #{$block}__ref {
            padding: 11px 12px;
            text-align: end;
        }
    
        #{$block}__icons {
            display: flex;
            gap: 10px;
        }
    
        #{$block}__quantity {
            padding-top: 11px;
            text-align: start;
        }
    
        #{$block}__comment {
            padding: 11px 12px;
        }
    }
}
