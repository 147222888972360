$block: '.header-balance-element';

#{$block} {
    display: flex;
    align-items: center;
    gap: 16px;

    #{$block}__text-element {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        #{$block}__red-text-total {
            color: rgba(233, 3, 58, 1);
        }
    }
}
