$block: '.alert-important';

#{$block} {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 18px 1fr;
    column-gap: 12px;
    width: 385px;
    height: max-content;
    padding: 15px 20px 15px 20px;
    border-radius: 6px;
    border: 1px solid #E9AE56;
    box-sizing: border-box;

    #{$block}__icon {
        color: #BD8E4B;
    }

    #{$block}__header-container {
        grid-column-start: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    #{$block}__context {
        margin-top: 12px;
        grid-column-start: 2;
        width: 100%;
        display: flex;
        flex-direction: column;

        ul {
            margin: 0;
            padding-left: 16px;
            list-style-position: outside;
            list-style-type: disc;
        }
    }

    #{$block}__context_hidden {
        display: none;
    }
}