$block: '.return-badge';

#{$block} {
    width: 16px;
    height: 16px;
    color: #ff003d80;
}

#{$block}__return-badge-tooltip-container .g-popup__content {
    border-radius: 5px;
    padding: 0 !important;
    background: rgba(76, 76, 76, 0.85);
}

#{$block}__return-badge-tooltip {
    padding: 8px 12px;
}

#{$block}__return-badge-tooltip-content {
    color: white;
}
