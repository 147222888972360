$block: '.header';

#{$block} {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    text-align: start;
    background-color: rgba(255, 255, 255, 1);
    gap: 12px;
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #e5e5e5;

    &__avatar{
        .g-user__name,
        .g-user__description {
            max-width: 104px;
        }
    }

    #{$block}__header-top {
        display: flex;
        justify-content: space-between;

        #{$block}__left-section {
            display: flex;
            align-items: center;
            gap: 20px;

            #{$block}__header-logo {
                margin-top: 4px;
                width: 92px;
                height: 44px;
            }
        }

        #{$block}__right-section {
            display: flex;
            align-items: center;
            gap: 16px;

            #{$block}__right-section-button {
                height: 44px;
                padding: 12px 24px;

                & .g-button__text {
                    display: flex;
                    gap: 12px;
                }
            }
        }
    }

    #{$block}__header-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 1920px) {
            font-size: 15px;
        }
    }
}