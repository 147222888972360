$block: '.basket-trash-modal';

#{$block} {
    .g-modal__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
    }

    #{$block}__content-container {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        #{$block}__content-text {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        #{$block}__close-icon {
            cursor: pointer;
        }
    }

    #{$block}__buttons-container {
        display: flex;
        gap: 12px;
        justify-content: space-between;

        #{$block}__cancel-button {
            padding: 0 40px;
        }

        #{$block}__confirm-button {
            padding: 0 40px;
            color: rgba(233, 3, 58, 1);
            transition: all 0.3s ease;
        }

        #{$block}__confirm-button:hover {
            color: rgba(233, 3, 58, 1);
        }
    }
}
