@use '@assets/styles/auth-variables.scss' as av;

$block: '.header-orders-items';

#{$block} {
    display: grid;
    text-align: center;
    background: av.$header-background;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-between;

    @media (max-width: 1440px) {
        top: 362px;
    }

    #{$block}__block {
        padding: 10px 12px;
    }

    #{$block}__date-order {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        text-align: start;
        cursor: pointer;
    }

    #{$block}__date-delivery {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        text-align: start;
        cursor: pointer;
    }

    #{$block}__status {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        cursor: pointer;
    }

    #{$block}__ref {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
    }

    #{$block}__name {
        text-align: start;
    }

    #{$block}__quantity {
        text-align: start;
    }

    #{$block}__price {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 8px;
        cursor: pointer;
    }

    #{$block}__sum {
        display: flex;
        align-items: flex-start;
        text-align: start;
        gap: 8px;
        cursor: pointer;

        .g-text {
            @media (max-width: 1440px) {
                width: 55px;
            }
        }
    }

    #{$block}__comment {
        text-align: start;
    }
}
