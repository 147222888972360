$block: '.not-found';

#{$block} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    #{$block}__image {
        width: 208px;
        height: 208px;
        padding: 16.64px 11.44px 16.64px 11.96px;
    }
}