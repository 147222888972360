$block: '.user-notification';

#{$block} {
    display: flex;
    width: 374px;
    height: 264px;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__subtitle {
        margin-top: 32px;
    }

    #{$block}__checkbox {
        margin-top: 16px;
    }

    #{$block}__checkbox:not(:first-of-type) {
        margin-top: 14px;
    }

    #{$block}__note {
        margin-top: 14px;
    }
}

#{$block}__alert {
    position: relative;
    left: -28px;
    top: 42px;
    gap: 10px;
    width: 374px;
    height: 146px;

    .g-flex {
        row-gap: 8px !important;
    }

    #{$block}__alert-list {
        text-indent: 2px;
        margin: 0;
        padding: 0 0 0 12px;
    }

    #{$block}__name-bot {
        color: #348BDC;
    }
}