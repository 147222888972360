$block: '.user-address-block';

#{$block} {
    display: flex;
    max-width: 467px;
    height: 236px;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__content {
        margin-top: 20px;
        display: inline-flex;
        align-items: flex-start;
    }

    // #{$block}__content:nth-of-type(2) {
    //     margin-top: 32px;
    // }

    #{$block}__icon {
        margin-right: 8px;
        min-width: 20px;
        min-height: 20px;
    }

    #{$block}__checkbox {
        margin-top: 20px;
    }
}