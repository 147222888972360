$block: '.orders-header-controls';

#{$block} {
    margin: 32px 0 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    #{$block}__controls-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        #{$block}__header-buttons {
            display: flex;
            gap: 12px;

            #{$block}__button-content {
                position: relative;
                display: inline-block;
                vertical-align: text-top;
                padding-right: 8px;

                #{$block}__check-icon {
                    position: absolute;
                    top: 0;
                    right: 8px;
                    width: 4px;
                    height: 4px;
                    background-color: rgba(233, 3, 58, 1);
                    border-radius: 50%;
                }
            }
        }
    }
}
