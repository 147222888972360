$block: '.home-page';

#{$block} {
    background-color: #ffffff;
    min-height: 100%;
    min-width: 1212px;
    padding-top: 64px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 68px);

    display: flex;
    flex-direction: column;
    gap: 16px;

    #{$block}__search-panel {
        margin-left: auto;
        margin-right: auto;
        width: 1100px;
        max-width: 1100px;
        display: flex;
        flex-direction: column;

        Button {
            margin-left: 12px;
        }

        Input {
            background-color: #ffffff;
        }

        #{$block}__catalog-link {
            color: #FFFFFF;
        }

        #{$block}__loader {
            margin-top: 132px;
            margin-left: auto;
            margin-right: auto;
        }

        #{$block}__brand-not-found {
            margin-top: 32px;
        }

        #{$block}__brand-suggestions {
            margin-top: 16px;

            #{$block}__brand-suggestions-header {
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                display: flex;
                flex-direction: row;
                gap: 8px;

                svg {
                    width: 24px;
                    height: 24px;
                    opacity: 0.3;
                }
            }

            #{$block}__brand-suggestions-header2 {
                margin-top: 12px;
                display: flex;
                flex-direction: row;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                div {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    background: #f4f6f7;
                    padding: 10px 12px;
                }

                div:nth-child(1) {
                    width: 184px;
                    min-width: 184px;
                    border-radius: 10px 0px 0px 0px;
                }

                div:nth-child(2) {
                    width: 164px;
                    min-width: 164px;
                }

                div:nth-child(3) {
                    flex-grow: 1;
                    border-radius: 0px 10px 0px 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            #{$block}__brand-suggestions-item {
                display: flex;
                flex-direction: row;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;

                div {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 10px 12px;
                }

                div:nth-child(1) {
                    width: 184px;
                    min-width: 184px;
                }

                div:nth-child(2) {
                    width: 164px;
                    min-width: 164px;
                }

                div:nth-child(3) {
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                #{$block}__brand-suggestions-article {
                    border-radius: 3px;
                    background: rgba(107, 132, 153, 0.15);
                    padding: 1px 4px;
                    width: auto;
                }
            }

            #{$block}__brand-suggestions-item:hover {
                background: rgba(0, 0, 0, 0.05);
            }
        }
    }
}
