$block: '.index-page';

#{$block} {
    margin: auto;
    width: 1140px;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    position: relative;
    z-index: 2;

    #{$block}__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 1px;

        img {
            width: 116px;
        }
    }

    #{$block}__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        p {
            font-family: Play, serif;
            font-size: 64px;
            font-weight: 700;
            line-height: 66px;
            text-align: left;
            margin: 96px 0 0;
        }

        img {
            position: absolute;
            right: 0;
            top: 40px;
            width: 441px;
        }

        #{$block}__btn-login {
            margin-top: 52px;
            width: 230px;
            background-color: #e58345;
            color: #fff;
        }
    }

    #{$block}__banner {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        margin-top: 120px;

        img {
            width: 100%;
        }
    }
}

#{$block}::before {
    content: '';
    position: absolute;
    width: 796px;
    height: 796px;
    right: -50px;
    background-image: url('../../assets/engine.png');
    background-size: cover;
    opacity: 0.07;
    z-index: -1;
}
