$block: '.items';

#{$block} {
    display: flex;
    flex-direction: column;
    width: 100%;

    #{$block}__row {
        min-height: 84px;
        display: grid;
        grid-template-columns:
            3%      // &__is-selected
            6.2%    // &__date
            15.5%   // &__article
            5%      // &__icons
            6.95%   // &__rating
            8.45%   // &__quantity
            9.5%   // &__delivery-days
            8.45%   // &__delivery-date
            9.05%   // &__price
            9.05%   // &__amount
            9.8%    // &__quantity-input
            9.1%    // &__aux-controls
        ;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: rgba(107, 132, 153, 0.15);
        }

        @media (min-width: 1920px) {
            grid-template-columns:
            3% 5.3% 1fr 4.3% 6.8% 7.2% 7.2% 7.2% 7.9% 7.9% 8.3% 7.9%;
        }

        @media (max-width: 1280px) {
            grid-template-columns:
                3.4%      // &__is-selected
                15.5%   // &__article
                5.6%      // &__icons
                9%   // &__rating
                9.6%   // &__delivery-days
                9.6%   // &__delivery-date
                10.3%   // &__price
                10.3%   // &__amount
                11.1%    // &__quantity-input
                10.5%    // &__aux-controls
            ;
            justify-content: space-between;
        }

        #{$block}__item {
            padding: 10px 12px;
        }
    
        #{$block}__date {
            display: flex;
            flex-direction: column;
    
            .time {
                color: #00000080;
            }
        }
    
        #{$block}__rating {
            padding: 10px 12px;
        }
    
        #{$block}__price {
            display: flex;
            flex-direction: column;
        }
    
        #{$block}__amount {
            display: flex;
            flex-direction: column;
        }
    
        #{$block}__quantity-input {
            text-align: end;
        }
    }
}
