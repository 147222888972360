$block: '.orders-empty';

#{$block} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;

    #{$block}__image {
        padding-bottom: 16px;
    }

    #{$block}__text-no-orders {
        text-align: center;
    }

    #{$block}__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}