$block: '.basket-error';

#{$block} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    gap: 16px;

    #{$block}__image {
        padding: 17.7px 12.5px 17.7px 12px;
    }

    #{$block}__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        #{$block}__link {
            cursor: pointer;
        }
    }
}