$block: '.orders-comment-tooltip';

#{$block}__text-comment {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    cursor: default;
}

#{$block}__tooltip-comment-content {
    white-space: normal;
    word-break: break-word;
}

#{$block}__tooltip-comment {
    padding: 8px 12px;
    max-width: 240px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
}

#{$block}__tooltip-comment-container .g-popup__content {
    border-radius: 8px;
    padding: 0 !important;
}