$block: '.basket-summary';

#{$block} {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 36px;
    padding: 16px 0px;
    margin-top: 16px;

    #{$block}__amounts {
        display: flex;
        flex-direction: column;
        margin-right: 36px;
        align-items: flex-end;
    }

    #{$block}__checkout {
        span {
            margin: auto;
        }

        #{$block}__checkout-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            #{$block}__checkout-icon {
                width: 20px;
                height: 20px;
                margin-left: 12px;
            }
        }
    }
}
