$block: '.aux-controls-tooltip';

#{$block}__aux-controls-tooltip-container .g-popup__content {
    border-radius: 5px;
    padding: 0 !important;
    background: rgba(76, 76, 76, 0.85);
}

#{$block}__aux-controls-tooltip {
    padding: 8px 12px;
}

#{$block}__aux-controls-tooltip-content {
    color: white;
}