@use '@assets/styles/variables.scss' as variables;
@use '@features/search/variables.scss' as sv;

$block: '.proposal';

#{$block}__proposal {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;

    &:last-child {
        display: flex;
        flex-direction: row;
        border-bottom: none;
    } 

    #{$block}__icons {
        width: sv.$icons-width;
    }

    #{$block}__rating {
        min-width: sv.$rating-min-width;
    }

    #{$block}__quantity {
        width: sv.$quantity-width;
    }

    #{$block}__delivery-days {
        width: sv.$delivery-days-width;
    }

    #{$block}__delivery-date {
        width: sv.$delivery-date-width;
    }

    #{$block}__price {
        width: sv.$price-width;
    }

    #{$block}__basket-controls {
        margin-left: auto;
        border-radius: 0px 10px 0px 0px;
        width: sv.$basket-controls-width;
    }
}

#{$block}__proposal:hover {
    background-color: #EAEDF0;
}

#{$block}__proposal_gen1 {
	animation: 5s gen_1_to_0 ease-out;
}

@keyframes gen_1_to_0 {
	from {
		background-color: #ffbe5c4d;
	}
	to {
		background-color: #ffbe5c00;
	}
}