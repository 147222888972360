$block: '.article-info';

#{$block}__item-header {
    display: flex;
    flex-wrap: wrap;
    padding: 11px 12px;

    &.custom-order {
        flex-direction: column;
        align-items: flex-start;
    
    }

    #{$block}__brand-name {
        margin-right: 4px;
    }
    
    #{$block}__break {
        flex-basis: 100%;
        height: 0;
    }
    
    #{$block}__article {
        border-radius: 3px;
        background: rgba(107, 132, 153, 0.15);
        padding: 1px 9px;
    }
    
    #{$block}__article-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}