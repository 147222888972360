$block: '.basket-empty';

#{$block} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;

    #{$block}__link {
        text-decoration: none;
    }
}