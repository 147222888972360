html,
body,
#root {
    height: 100%;
    min-width: 320px;
    margin: 0;
}

@font-face {
    font-family: 'ArianAMUMonoRegular';
    src: url('../fonts/ArianAMUMono/ArianAMUMonoRegular/ArianAMUMonoRegular.eot');
    src:
        url('../fonts/ArianAMUMono/ArianAMUMonoRegular/ArianAMUMonoRegular.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/ArianAMUMono/ArianAMUMonoRegular/ArianAMUMonoRegular.woff') format('woff'),
        url('../fonts/ArianAMUMono/ArianAMUMonoRegular/ArianAMUMonoRegular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

.g-root:not(.g-root_mobile):not(.g-root_native-scrollbar) {
    scrollbar-color: initial !important;
}

::-webkit-scrollbar {
    width: 12px; /* Ширина вертикального скролла */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* Цвет ползунка */
}

::-webkit-scrollbar-button {
    display: none; /* Убираем стрелки */
}
