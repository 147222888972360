$block: '.current-balance-card';

#{$block} {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: #F5FAFF;
    border-radius: 20px;
    min-width: 380px;

    #{$block}__current-balance {
        display: flex;
        flex-direction: column;
        gap: 20px;

        #{$block}__current-balance-icon {
            color: rgba(52, 139, 220, 1);
        }

        #{$block}__current-balance-amount {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    #{$block}__debt {
        display: flex;
        gap: 48px;
        padding: 64px 0 32px 0;

        #{$block}__debt-amount {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 145px;
        }

        #{$block}__debt-overdue {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 185px;
        }
    }

    #{$block}__orders {
        display: flex;
        flex-direction: column;
        gap: 16px;

        #{$block}__orders-amount {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        #{$block}__orders-statuses {
            display: flex;
            gap: 48px;

            #{$block}__status {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 145px;
            }
        }
    }
}
