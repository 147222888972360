$block: '.footer-block';

#{$block}__container {
    margin: 60px 0 40px;
    padding: 0 24px 0;
    background-color: #d5d8da;
    border-radius: 20px;
    height: 146px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    #{$block}__column-1 {
        display: flex;
        flex-direction: column;
        margin-top: 31px;

        span {
            margin-bottom: 8px;

            &:first-of-type {
                margin-bottom: 24px;
            }
        }
    }

    #{$block}__block-contact {
        display: flex;
        position: relative;

        .g-button__text {
            display: flex;
            align-items: center;
        }

        #{$block}__column-2 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 23px;

            .g-text {
                width: 160px;
                padding: 0 12px;
            }

            .g-button {
                width: 164px;
                padding: 0;
            }

            .g-button:first-of-type {
                margin-top: 23px;
            }

            .icon-hidden {
                visibility: hidden;
            }
        }

        #{$block}__column-3 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 82px;

            .g-button:first-of-type {
                margin-top: 66px;
                max-width: max-content;
            }
        }
    }
}

#{$block}__footer-minimal {
    margin: 48px 0 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 34px;
    isolation: isolate;
    background: rgba(242, 242, 242, 1);
    border-radius: 10px;
    flex-shrink: 0;
}

#{$block}__tooltip-container .g-popup__content {
    border-radius: 8px;
    padding: 0 !important;
}

#{$block}__tooltip {
    padding: 12px 16px;
}

#{$block}__icon {
    color: #e58345;
    margin-right: 8px;
}
