$block: '.sort-selector';

#{$block} {
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    justify-content: center;

    #{$block}__title {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    #{$block}__caret {
        width: 16px;
        height: 16px;
        margin-top: 2px;
    }

    #{$block}__caret.active {
        color: #bd8e4b;
    }
}
