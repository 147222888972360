$block: '.dnd-item';

#{$block} {
    padding: 4px 12px;
    border-radius: 6px;

    &--dragging {
        background-color: rgba(255, 190, 92, 0.3);
    }

    #{$block}__drag-container {
        display: flex;
        align-items: center;
        gap: 12px;

        #{$block}__drag-icon {
            color: rgba(0, 0, 0, 0.3);
        }

        #{$block}__check-container {
            width: 16px;
            height: 16px;
            cursor: pointer;

            #{$block}__check-icon {
                color: rgba(189, 142, 75, 1);
            }
        }
    }
}
