@use '@assets/styles/auth-variables.scss' as av;
@use '@features/search/variables' as sv;

$block: '.section-items-header';

#{$block} {
    position: sticky;
    top: 231px;
    z-index: 30;
    background-color: av.$main-background;
    box-shadow: 0px 8px 8px 0px rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 19px;
    
    #{$block}__title {
        width: sv.$search-header-width;
        border-radius: 10px 0px 0px 0px;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        background: av.$header-background;
        padding: 10px 12px;
        text-align: left;

        @media screen and (min-width: sv.$wide-breakpoint) {
            width: sv.$search-header-width-wide;
        }
    }

    #{$block}__proposal-header {
        display: flex;
        flex-direction: row;
        width: sv.$proposals-width;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        background: av.$header-background;
        border-radius: 0px 10px 0px 0px;
        padding: 8px 0px;
        text-align: center;

        @media screen and (min-width: sv.$wide-breakpoint) {
            width: sv.$proposals-width-wide;
        }

        #{$block}__icons {
            width: sv.$icons-width;
            padding: 2px 12px;
        }
    
        #{$block}__rating {
            justify-content: center;
            padding: 2px 12px;
        }
    
        #{$block}__quantity {
            justify-content: right;
            width: sv.$quantity-width;
            padding: 2px 12px;
        }
    
        #{$block}__delivery-days {
            justify-content: right;
            width: sv.$delivery-days-width;
            padding: 2px 12px;
        }
    
        #{$block}__delivery-date {
            justify-content: right;
            width: sv.$delivery-date-width;
            padding: 2px 12px;
        }
    
        #{$block}__price {
            justify-content: right;
            width:   sv.$price-width;
            padding: 2px 12px;
        }
    
        #{$block}__basket-controls {
            margin-left: auto;
            padding: 0 12px;
            width: sv.$basket-controls-width;

            #{$block}__show-all-button {
                position: absolute;
                right: 12px;
            }
        }
    }
}