$block: '.credit-limit-card';

#{$block} {
    display: flex;
    flex-direction: column;
    gap: 20px;

    #{$block}__available-amount-card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 32px;
        border-radius: 20px;
        width: 380px;
        background: rgba(244, 246, 247, 0.50);
        height: 100%;

        #{$block}__icon {
            color: rgba(189, 142, 75, 1);
        }

        #{$block}__container {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    #{$block}__paymant-card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 32px;
        border-radius: 20px;
        width: 380px;
        background: rgba(244, 246, 247, 0.50);
        height: 100%;

        #{$block}__icon {
            color: rgba(0, 0, 0, 0.5);
        }

        #{$block}__payment {
            display: flex;
            flex-direction: column;
            gap: 8px;

            #{$block}__payment-date {
                display: flex;
                gap: 16px;
            }
        }
    }
}