$block: '.section';

#{$block} {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    &_hidden {
        margin-bottom: 12px;
    }

    #{$block}__loader-container {
        display: flex;
        justify-content: center;
    }

    #{$block}__not-found {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        height: 42px;
    }
}