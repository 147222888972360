@use '@assets/styles/auth-variables.scss' as av;

$block: '.header-basket-items';

#{$block} {
    display: grid;
    grid-template-columns:
        3%      // &__is-selected
        6.2%    // &__date
        15.5%   // &__article
        5%      // &__icons
        7.5%   // &__rating
        8.45%   // &__quantity
        9.5%   // &__delivery-days
        8.45%   // &__delivery-date
        9.05%   // &__price
        9.05%   // &__amount
        9.8%    // &__quantity-counter
        9.1%    // &__aux-controls
    ;
    text-align: center;
    background: av.$header-background;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-between;

    @media (min-width: 1920px) {
        grid-template-columns:
            3% 5.3% 1fr 4.3% 6.8% 7.2% 7.2% 7.2% 7.9% 7.9% 8.3% 7.9%;
        ;
    }

    @media (max-width: 1280px) {
        grid-template-columns:
            3.4%      // &__is-selected
            15.5%   // &__article
            5.6%      // &__icons
            9%   // &__rating
            9.6%   // &__delivery-days
            9.6%   // &__delivery-date
            10.3%   // &__price
            10.3%   // &__amount
            11.1%    // &__quantity-input
            10.5%    // &__aux-controls
        ;
        justify-content: space-between;
    }

    #{$block}__block {
        padding: 10px 12px;
    }

    #{$block}__date {
        display: flex;
        align-items: center;
        gap: 8px;
        text-align: start;
        cursor: pointer;
    }

    #{$block}__article {
        text-align: start;
    }

    #{$block}__rating {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        text-align: end;
        cursor: pointer;
    }

    #{$block}__quantity {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        text-align: end;
        cursor: pointer;
    }

    #{$block}__delivery-days {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        text-align: end;
        cursor: pointer;
    }

    #{$block}__delivery-date {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        text-align: end;
        cursor: pointer;
    }

    #{$block}__price {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        text-align: end;
        cursor: pointer;
    }

    #{$block}__amount {
        text-align: end;
    }
}