$block: '.popup-clients-address';

#{$block} {
    width: 351px;
    margin-top: 4px;

    #{$block}__header {
        padding: 12px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    #{$block}__content {
        padding: 4px;

        #{$block}__item {
            display: flex;
            gap: 16px;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: 10px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            #{$block}__item-icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                color: rgba(189, 142, 75, 1);
            }

            #{$block}__item-info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
