$block: '.official-dealer-badge';

#{$block} {
    width: 16px;
    height: 16px;
    color: #3697f180;
}

#{$block}__official-dealer-badge-tooltip-container .g-popup__content {
    border-radius: 5px;
    padding: 0 !important;
    background: rgba(76, 76, 76, 0.85);
}

#{$block}__official-dealer-badge-tooltip {
    padding: 8px 12px;
}

#{$block}__official-dealer-badge-tooltip-content {
    color: white;
}