$block: '.brand-filter';

#{$block}__find-brand {
    display: block;
    max-width: 200px;
    margin: auto;
}

#{$block}__find-brand_hidden {
    display: none !important;
}

#{$block} {
    #{$block}__header {
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding: 8px 0px;

        span {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        svg {
            width: 12px;
            height: 12px;
        }

        #{$block}__header-column {
            display: flex;
            flex-direction: row;
            gap: 4px;
            cursor: pointer;

            #{$block}__header-sorter {
                display: flex;
                width: 20px;
                height: 20px;

                #{$block}__caret {
                    margin: auto;
                }

                #{$block}__caret_active {
                    color: #bd8e4b;
                }
            }
        }
    }

    #{$block}__brand-list {
        display: flex;
        flex-direction: column;
        gap: 7px;

        #{$block}__brand-row {
            display: flex;
            flex-direction: row;

            #{$block}__brand-name {
                width: 128px;

                @media screen and (min-width: 1920px) {
                    font-size: 15px;
                }
            }

            #{$block}__brand-min-price {
                flex-grow: 1;
                text-align: right;
                color: rgba(0, 0, 0, 0.5);
                padding-right: 4px;
                @media screen and (min-width: 1920px) {
                    font-size: 15px;
                }
            }
        }

    }

    #{$block}__brand-list_scroll {
        max-height: 543px;
        overflow: auto;
        // scrollbar-width: thin;
    }

    #{$block}__footer {
        display: flex;
        flex-direction: row;
        gap: 4px;
        padding: 8px 0px;
        line-height: 16px;
        align-self: center;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
        }

        #{$block}__icon {
            margin-top: 1px;
        }

        #{$block}__icon_rotation {
            transform: rotate(180deg);
        }
    }

    #{$block}__footer_hidden {
        display: none;
    }
}