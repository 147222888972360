$block: '.orders-page';

#{$block} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    #{$block}__no-orders-header {
        margin-top: 32px;
    }

    #{$block}__loader-new-orders {
        display: flex;
        width: 100%;
        padding-top: 20px;
        justify-content: center;
    }

    #{$block}__orders-header-controls-sticky-box {
        z-index: 50;
        width: 100%;
        background-color: #ffffff;
        padding-bottom: 1px;
    }
}

#{$block}__loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
