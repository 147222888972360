$block: '.profile-page';

#{$block} {
    width: 100%;
    display: grid;
    grid-template-columns: 305px 1fr;
    grid-template-rows: 104px 1fr;
    column-gap: 20px;

    #{$block}__header {
        margin: 32px 0px;
        display: block;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    #{$block}__content {
        width: 100%;
    }
}