$block: '.custom-range-date-picker';

#{$block}__date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    cursor: pointer;
    border-radius: 6px;
    padding: 0 6px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: rgb(242, 242, 242);
    }

    &:has(#{$block}__clear-icon:hover) {
        background-color: #ffffff;
    }

    #{$block}__icon-container {
        display: flex;
        align-items: center;
        gap: 8px;

        #{$block}__clear-icon {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.5);

            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }

        #{$block}__calendar-icon {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.5);

            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}

#{$block}__popup {
    #{$block}__popup-container {
        width: 240px;
    
        #{$block}__date-picker-container {
            display: flex;
            flex-direction: column;
            padding: 12px;
            gap: 8px;
        }
    
        #{$block}__presets-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 4px;
    
            #{$block}__preset-item {
                cursor: pointer;
                padding: 0 12px;
            }
        }
    }
}

.g-popup {
    z-index: 1001;
}