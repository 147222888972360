$block: '.tooltip-comment';

#{$block} {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    #{$block}__tooltip-comment-description {
        display: flex;
        flex-direction: column;
        
        #{$block}__tooltip-comment-count {
            text-align: end;
        }
    }

    #{$block}__tooltip-comment-buttons {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        #{$block}__tooltip-comment-button {
            padding: 0 30px;
        }
    }
}
