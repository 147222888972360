@use '@assets/styles/variables.scss' as variables;
@use '@features/search/variables.scss' as search-variables;

$block: '.basket-controls';

#{$block} {
    padding: 7px 12px 6px 12px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    #{$block}__button.basket-button {
        --_--background-color: #FFBE5C4D;

        #{$block}__cart-icon {
            color: #BD5C0A;
            margin-top: 6px;
        }
    }

    #{$block}__button.basket-button-added {
        --_--background-color: rgba(50, 186, 118, 0.3);

        #{$block}__check-icon {
            color: rgba(45, 139, 93, 1);
            margin-top: 6px;
        }
    }
}