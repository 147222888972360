$block: '.rating';

#{$block} {
    padding: 10px 20px;
}

.g-progress__text {
    font-size: 15px;
    z-index: 1;
    color: #00000080;
}

.g-progress__text-inner {
    display: none; // Похоже на баг в компоненте Progress, два раза рисуется текст
}