$block: '.orders-popup-settings-content';

#{$block} {
    width: 320px;

    #{$block}__header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 8px;
        border-bottom: 1px solid var(--Line-Generic, rgba(0, 0, 0, 0.10));

        #{$block}__save-button {
            background-color: rgba(255, 190, 92, 0.3);
            color: rgba(189, 92, 10, 1);
    
            &--saved {
                background-color: rgba(50, 186, 118, 0.3);
                color: rgba(45, 139, 93, 1);
            }
        }
    }


    #{$block}__content {
        padding: 4px;
    }

    #{$block}__footer-content {
        display: flex;
        gap: 8px;
        padding: 8px;
        border-top: 1px solid var(--Line-Generic, rgba(0, 0, 0, 0.10));
        
        #{$block}__reset-button, #{$block}__apply-button {
            width: 50%;
        }
    }
}