$search-header-height-with-gaps: 231px;
$header-height: 68px;
$header-background: #eaedf0;
$default-border-radius: 10px;
$page-top-padding: 12px;
$page-header-height: 136px;

$wide-breakpoint: 1440px;
$search-header-width: 16%;
$search-header-width-wide: 28%;
$proposals-width: 84%;
$proposals-width-wide: 72%;

$icons-width: 5.8%;
$quantity-width: 11.5%;
$delivery-days-width: 11.5%;
$delivery-date-width: 11.5%;
$price-width: 12.5%;
$basket-controls-width: 17.4%;

$rating-min-width: 69px;

@media (max-width: wide-breakpoint) {
    $icons-width: 4.93%;
    $quantity-width: 9.78%;
    $delivery-days-width: 9.78%;
    $delivery-date-width: 9.78%;
    $price-width: 10.63%;
    $basket-controls-width: 14.79%; 
}