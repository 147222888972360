$block: '.personal-account-layout';

#{$block} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    #{$block}__content {
        display: flex;
        justify-content: center;
        flex: 1;
    }
}