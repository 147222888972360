$block: '.orders-filters';

#{$block} {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: rgba(244, 246, 247, 0.5);
    padding: 12px 12px 16px 12px;
    margin-top: 12px;

    #{$block}__filters-container {
        display: grid;
        grid-template-columns: 240px 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 8px 20px;
        grid-template-areas:
            'date-order status brand price-block comment'
            'date-delivery ref article sum-block .';

        @media (max-width: 1420px) {
            grid-template-columns: 240px 240px 308px;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-areas:
                'date-order ref price-block'
                'date-delivery brand sum-block'
                'status article comment';
        }

        #{$block}__date-order {
            grid-area: date-order;
            width: 240px;
            background-color: #ffffff;
            border-radius: 6px;
            height: 28px;
        }

        #{$block}__date-delivery {
            grid-area: date-delivery;
            width: 240px;
            background-color: #ffffff;
            border-radius: 6px;
            height: 28px;
        }

        #{$block}__status {
            grid-area: status;
            background-color: #ffffff;
            border-radius: 6px;
        }

        #{$block}__ref {
            grid-area: ref;
            background-color: #ffffff;
            border-radius: 6px;
        }

        #{$block}__brand {
            grid-area: brand;
            width: 218px;
            background-color: #ffffff;
            border-radius: 6px;

            @media (max-width: 1420px) {
                width: 240px;
            }
        }

        #{$block}__article {
            grid-area: article;
            width: 218px;
            background-color: #ffffff;
            border-radius: 6px;

            @media (max-width: 1420px) {
                width: 240px;
            }
        }

        #{$block}__price-block {
            grid-area: price-block;
            display: flex;
            gap: 4px;
            width: 316px;

            label {
                color: #00000080;
            }

            input {
                background-color: #ffffff;
            }
        }

        #{$block}__sum-block {
            grid-area: sum-block;
            display: flex;
            gap: 4px;
            width: 316px;

            label {
                color: #00000080;
            }

            input {
                background-color: #ffffff;
            }
        }

        #{$block}__comment {
            grid-area: comment;
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    #{$block}__actions-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        #{$block}__button-save {
            background-color: rgba(255, 190, 92, 0.3);
            color: rgba(189, 92, 10, 1);

            &--saved {
                background-color: rgba(50, 186, 118, 0.3);
                color: rgba(45, 139, 93, 1);
            }
        }
    }
}
