$block: '.retail-balance-card';

#{$block} {
    display: flex;
    flex-direction: column;
    min-width: 388px;
    padding: 28px 28px 32px 28px;
    background: rgba(244, 246, 247, 0.50);
    border-radius: 20px;
    gap: 20px;

    #{$block}__icon {
        color: rgba(0, 0, 0, 0.5);
    }

    #{$block}__current-orders {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    #{$block}__orders-status {
        display: flex;
        gap: 48px;
        
        #{$block}__status {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

}