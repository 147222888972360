$block: '.user-info';

#{$block} {
    display: flex;
    max-width: 430px;
    height: 100%;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__avatar {
        margin-top: 32px;
        width: 54px;
        height: 54px;
    }

    #{$block}__content {
        margin-top: 20px;
        display: inline-flex;
        align-items: flex-start;
    }

    #{$block}__icon {
        margin-right: 8px;
        min-width: 20px;
        min-height: 20px;
    }

    #{$block}__alert {
        margin-top: auto;
    }
}