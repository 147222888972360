$block: '.basket-page';

#{$block} {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

#{$block}__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}