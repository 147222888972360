$block: '.level-price';

#{$block} {
    grid-row: span 4;
    display: flex;
    width: 302px;
    height: max-content;
    padding: 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background: #F5FAFF;
    box-sizing: border-box;

    #{$block}__header-container {
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    #{$block}__input {
        margin-top: 40px;
        background-color: #FFFFFF;
    }

    #{$block}__paragraph {
        margin-top: 20px;
    }

    #{$block}__checkbox {
        margin-top: 20px;

        .g-checkbox__indicator {
            background-color: #FFFFFF;
        }
    }

    #{$block}__checkbox_invalid {
        --g-color-line-generic-accent: #FF003DCC;
    }

    #{$block}__checkbox:not(:first-of-type) {
        margin-top: 14px;
    }
}

#{$block}__tooltip {
    width: 195px;
}

#{$block}__profile-toaster {
    top: 15% !important;
    width: 259px !important;
}