$block: '.custom-toaster';

#{$block} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(70, 63, 63, 0.1);
    z-index: 1010;
}

.g-toaster {
    width: var(--g-toaster-width, 400px);
    position: fixed;
    top: var(--g-toaster-top, 50%);
    left: 50%;
    transform: translate(-50%, -50%);
    inset-block-end: auto;
    

    .g-toast {
        background-color: lighten(#FF6B6C, 80%);
    }

    .g-toast__title {
        width: 304px;
        padding-inline-end: 10px;
        white-space: pre-line;
    }

    .g-toast__content {
        width: var(--g-toaster-content-width, 304px);
    }
}

.g-toast__actions {
    display: contents;

    .g-button {
        margin-top: 8px;
    }

    .g-button_width_auto {
        width: 304px;
    }
}