$block: '.alert-percent';

#{$block} {
    grid-column-start: 2;
    display: grid;
    grid-template-columns: 18px 1fr;
    column-gap: 12px;
    width: 385px;
    height: max-content;
    padding: 15px 20px 15px 20px;
    border-radius: 6px;
    border: 1px solid rgba(54, 151, 241, 0.80);
    box-sizing: border-box;

    #{$block}__icon {
        color: #348BDC;
    }

    #{$block}__header-container {
        grid-column-start: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    #{$block}__chevron_turn {
        transform: rotate(180deg);
    }

    #{$block}__context {
        margin-top: 20px;
        grid-column-start: 2;
        width: 100%;
        display: flex;
        flex-direction: column;

        p {
            margin: 0;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
        }

        p:last-of-type {
            margin-top: 4px;
        }

        ol {
            padding: 0 0 0 2px;
            margin: 20px 0 8px 0;
            list-style: none;
            list-style-position: inside;

            li:nth-child(2)>p {
                margin-top: 7px;
            }

            p {
                font-weight: 600;
            }

            ul {
                margin-top: 4px;
                padding: 0 0 0 4px;
                list-style-position: inside;
                list-style-type: disc;
            }
        }
    }

    #{$block}__context_hidden {
        display: none;
    }
}