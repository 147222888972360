$block: '.user-card';

#{$block} {
    position: absolute;
    top: calc(50% + 10px);
    right: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    max-width: 248px;
    box-sizing: border-box;

    &__title-company {
        margin-top: 4px;
    }

    #{$block}__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        #{$block}__close {
            cursor: pointer;
        }
    }

    #{$block}__user {
        display: flex;
        flex-direction: column;
        padding: 16px 0 24px 0;

        #{$block}__avatar {
            margin-bottom: 8px;
        }
    }

    #{$block}__button-profile .g-button__text {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    #{$block}__button-exit {
        margin-top: 8px;

        .g-button__text {
            display: flex;
            align-items: center;
            gap: 8px;
            color: rgb(233, 3, 58);
        }
    }
}