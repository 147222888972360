$block: '.profile-sidebar';

#{$block} {
    width: 305px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background: var(--Ultra-Light-Grey-BG, rgba(244, 246, 247, 0.50));
    padding: 28px;
    box-sizing: border-box;

    #{$block}__menu-items {
        display: flex;
        flex-direction: column;
    }

    #{$block}__menu-footer {
        display: flex;
        flex-direction: column;
    }

    #{$block}__button {
        margin-bottom: 14px;
        display: flex;
        justify-content: left;
        width: 100%;
    }

    #{$block}__button_primary {
        --g-color-base-generic: #fff;
    }

    #{$block}__button_logout {
        --g-color-base-generic: #ffffff00;
        color: #E9033A;
        margin-bottom: 0;
    }

    #{$block}__button_small {
        width: 210px;
    }
}