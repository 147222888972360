$block: '.modal-quit';

#{$block}__modal {
    background-color: rgba(0, 0, 0, 0.05);
    --g-modal-border-radius: 8px;

    .g-modal__content {
        box-sizing: border-box;
        padding: 16px 16px;
        width: 300px;
        height: 130px;
        display: grid;
        gap: 40px 12px;
        grid-template-columns: repeat(2, 1fr);
    }

    .modal-btn {
        width: 128px;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .modal-btn:last-of-type {
        color: #E9033A;
        background-color: transparent;
    }
}

#{$block}__header {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;

    .g-icon {
        color: rgba(0, 0, 0, 0.5);
    }

    .g-icon:hover {
        cursor: pointer;
        color: rgba(0, 0, 0);
    }
}