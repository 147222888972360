$block: '.basket-header-controls';

#{$block} {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    padding: 10px 0px 10px 12px;
    gap: 16px;
    align-items: center;

    #{$block}__select-all {
        padding: 6px 0px 6px 0px;
    }

    #{$block}__delete-selected {
        display: flex;
        align-items: center;

        .g-button__text {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    #{$block}__check-items {
        display: flex;
        align-items: center;
        margin-left: auto;

        .g-button__text {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}
