$block: '.balance-page';

#{$block} {
    display: flex;
    flex-direction: column;
    width: 100%;

    #{$block}__header {
        margin: 32px 0;
    }

    #{$block}__content {
        display: flex;
        gap: 20px;
    }
}

#{$block}__loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
