@use '@assets/styles/auth-variables.scss' as av;
@use '@features/search/variables.scss' as sv;

$block: '.search-page';

#{$block} {
    padding-top: sv.$page-top-padding;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    width: 100%;

    #{$block}__results-panel {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-height: calc(100vh - #{sv.$page-top-padding} - #{sv.$page-header-height});

        #{$block}__header-background {
            background-color: av.$main-background;
            height: sv.$search-header-height-with-gaps;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 40;
        }

        #{$block}__search-bar-sticky-box {
            z-index: 100;
        }

        #{$block}__results-searching {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        #{$block}__results-searching.hidden {
            display: none;
        }

        #{$block}__results-not-found {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -(sv.$search-header-height-with-gaps);
        }

        #{$block}__results-not-found.hidden {
            display: none;
        }

        #{$block}__results-found {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        #{$block}__results-found.hidden {
            display: none;
        }
    }
}
