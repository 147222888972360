$block: '.orders-status';

#{$block} {
    display: flex;
    flex-direction: column;

    @media (max-width: 1440px) {
        max-width: 101px;
        white-space: nowrap;
        overflow: hidden;
        transition: width 0.5s ease;

        &:hover {
            width: 136px;
        }
    }

    #{$block}__status {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
            min-width: 16px;
        }
    }

    #{$block}__date {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-top: 4px;

        svg {
            min-width: 16px;
            vertical-align: sub;
        }

        #{$block}__update-icon {
            color: rgba(0, 0, 0, 0.3);
        }
    }
}

#{$block}__update-tooltip {
    padding: 12px 16px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

#{$block}__update-tooltip-container .g-popup__content {
    border-radius: 8px;
    padding: 0 !important;
    position: relative;
    margin-bottom: 5px;

    &::after {
        content: '';
        position: absolute;
        width: 19px;
        height: 9px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url('../../../../../../../../assets/pin-white-bg.svg');
        background-size: cover;
    }
}

#{$block}__update-tooltip-container {
    z-index: 45 !important;
}
