@use '@assets/styles/variables.scss' as variables;

$block: '.remind-pass-page';

#{$block} {
  background-color: rgba(0,0,0,0);

  #{$block}__link-back, #{$block}__link-back span.g-button__text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #{$block}__like-old-link {
    color: variables.$colorBlueClassic;
  }

  & .g-modal__content {
    /* Form_feedback */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 64px;
    gap: 20px;
    isolation: isolate;

    position: absolute;
    width: 468px;
    left: calc(50% - 468px/2);
    top: 10rem;

    /* Base Float/Modal

    --g-color-base-modal

    Floating components with a veil (like popups)
    */
    background: #FFFFFF;
    border-radius: 20px;

    font-family: Inter, sans-serif;

    .g-text_variant_header-2 {
      //styleName: Header/Header 2;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    #{$block}__input {
      margin-top: 15px;
      width: 100%;
      &:last-child {
        margin-top: 30px;
      }
      &:first-child {
        margin-top: 0;
      }

      #{$block}__remind-pass {
        color: #E58345;
      }
    }

    #{$block}__input > * {
      width: 100%;
    }
  }
}
