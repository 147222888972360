$block: '.login-page';

#{$block}::before {
  content: '';
  position: absolute;
  width: 998px;
  height: 998px;
  background-image: url('../../assets/engine.png');
  background-repeat: no-repeat;
  transform: translate(50%,0);
  opacity: 0.07;
  z-index: -1;
}

#{$block} {
  background-color: rgba(0,0,0,0);
  overflow: hidden;

  #{$block}__exit {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    padding: 6px;
    gap: 8px;

    &:hover {
      background-color: rgba(0,0,0,.05);
      cursor: pointer;
      border-radius: 6px;
    }
  }

  & .g-modal__content {
    /* Form_feedback */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 64px;
    gap: 20px;
    isolation: isolate;

    position: absolute;
    width: 468px;
    box-sizing: border-box;
    left: calc(50% - 468px/2);
    top: 10rem;

    /* Base Float/Modal

    --g-color-base-modal

    Floating components with a veil (like popups)
    */
    background: #FFFFFF;
    border-radius: 20px;

    font-family: Inter, sans-serif;

    .g-text_variant_header-2 {
      //styleName: Header/Header 2;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    #{$block}__input {
      margin-top: 15px;
      width: 100%;
      &:last-child {
        margin-top: 30px;
      }
      &:first-child {
        margin-top: 0;
      }

      #{$block}__remind-pass {
        color: #E58345;
      }
    }

    #{$block}__input > * {
      width: 100%;
    }
  }
}
