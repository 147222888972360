@use '@assets/styles/variables.scss' as variables;
@use '@features/search/variables.scss' as search-variables;

$block: '.filters';

$header-background: #EAEDF0;
$default-border-radius: 10px;

#{$block} {
    width: 240px;

    @media screen and (min-width: 1920px) {
        width: 304px;
    }

    &__filter-sticky {
        z-index: 50;
    }

    #{$block}__filter-header {
        background-color: $header-background;
        border-radius: $default-border-radius $default-border-radius 0px 0px;
        padding: 20px 12px;
        display: flex;
        flex-direction: row;

        button {
            width: 28px;
            height: 28px;
            align-self: self-end;

            span {
                margin-top: 3px;
            }
        }

        #{$block}__filter-title {
            flex-grow: 1;
        }
    }

    #{$block}__filter-controls {
        margin-top: 8px;
        padding: 0px 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        #{$block}__filter-options {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
}