$block: '.aux-controls-orders';

#{$block} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    padding: 6px 12px;

    #{$block}__button {
        width: 28px;
        height: 28px;

        svg {
            margin-top: 6px;
        }
    }

}

#{$block}__tooltip-content {
    color: white;
}

#{$block}__tooltip {
    padding: 8px 12px;
}

#{$block}__tooltip-container .g-popup__content {
    border-radius: 5px;
    padding: 0 !important;
    background: rgba(76, 76, 76, 0.85);
}

#{$block}__tooltip-container {
    z-index: 45 !important;
}