$block: '.basket-btn';

#{$block}__button {
    display: flex;
    align-items: center;

    #{$block}__block {
        position: relative;
        display: flex;

        #{$block}__quantity {
            position: absolute;
            top: -10px;
            left: 12px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background-color: #ffbe5c;
            padding: 0 4px;
            height: 15px;

            span {
                font-size: 12px;
                white-space: nowrap;
            }
        }

        #{$block}__quantity_hidden {
            visibility: hidden;
        }

        #{$block}__icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }

        #{$block}__cost {
            line-height: 20px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
        }
    }
}
