$block: '.check-available-modal';

#{$block} {
    .g-modal__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 24px;
        width: 300px;
    }
}